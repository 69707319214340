<template>
  <div class="organization-view scrollbar">
    <el-row :gutter="25">
      <!--      <el-col :span="12">-->
      <!--        <el-card>-->
      <!--          <div slot="header" class="clearfix">-->
      <!--            <span>基地</span>-->
      <!--            <i v-if="isAuthed('sys_org_add')" class="el-icon-circle-plus-outline add-icon" @click="append(1)"></i>-->
      <!--          </div>-->
      <!--          <el-tree-->
      <!--            :data="baseList"-->
      <!--            :props="treeProps"-->
      <!--            node-key="id"-->
      <!--            default-expand-all-->
      <!--            draggable-->
      <!--            @node-drag-start="() => type = 1"-->
      <!--            @node-drop="handleDrop"-->
      <!--          >-->
      <!--            <div class="custom-tree-node" slot-scope="{ node, data }">-->
      <!--              <span>{{ node.label }}</span>-->
      <!--              <span class="tree-icons">-->
      <!--                <i class="el-icon-edit" v-if="isAuthed('sys_org_update')"  @click="edit(data, 1)"></i>-->
      <!--                <el-popconfirm v-if="isAuthed('sys_org_delete')"-->
      <!--                               title="确认删除吗？"-->
      <!--                               @onConfirm="remove(data, 1)"-->
      <!--                >-->
      <!--                  <i slot="reference" class="el-icon-remove-outline"></i>-->
      <!--                </el-popconfirm>-->
      <!--              </span>-->
      <!--            </div>-->
      <!--          </el-tree>-->
      <!--        </el-card>-->
      <!--      </el-col>-->
      <el-col :span="12">
        <el-card>
          <div slot="header" class="clearfix">
            <span>组织机构</span>
            <i class="el-icon-circle-plus-outline add-icon" @click="append(2)"></i>
          </div>
          <el-tree
            :data="departmentList"
            :props="treeProps"
            node-key="id"
            default-expand-all
            draggable
            @node-drag-start="() => type = 2"
            @node-drop="handleDrop"
          >
            <div class="custom-tree-node" slot-scope="{ node, data }">
              <span>{{ node.label }}</span>
              <span class="tree-icons">
                <i class="el-icon-edit" @click="edit(data, 2)"></i>
                <el-popconfirm
                  title="确认删除吗？"
                  @onConfirm="remove(data, 2)"
                >
                  <i slot="reference" class="el-icon-remove-outline"></i>
                </el-popconfirm>
              </span>
            </div>
          </el-tree>
        </el-card>
      </el-col>
    </el-row>
    <el-dialog
      title="新增"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="() => dialogVisible = false"
      destroy-on-close
    >
      <el-form :model="form" ref="form" status-icon>
        <el-form-item label="机构类型" prop="type">
          <el-select clearable filterable v-model="form.type">
            <el-option
              v-for = "item in typeoptions"
              :key = "item.value"
              :label = "item.label"
              :value = "item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="父级名称" prop="parentId">
          <el-select clearable filterable v-model="form.parentId">
            <el-option v-for="o in options" :key="o.id" :label="o.name" :value="o.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="节点名称"
          prop="departmentName"
          :rules="{required:true,message:'节点名称不能为空'}"
        >
          <el-input v-model="form.departmentName"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" :loading="submiting" @click="submitAppend">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { flattenArray } from '@/utils/'

export default {
  name: 'person-organization',

  data () {
    return {
      dialogVisible: false,
      submiting: false,
      typeoptions: [
        {
          value: '1',
          label: '部门'
        },
        {
          value: '2',
          label: '站点/基地'
        }
      ],
      form: {
        departmentName: '',
        parentId: '',
        type: ''
      },
      baseList: [],
      departmentList: [],
      optionsList: [],
      treeProps: {
        label: 'name',
        children: 'list'
      }
    }
  },

  computed: {
    options () {
      const list = this.departmentList
      return flattenArray(list, 'children', true)
    }
  },

  watch: {
    dialogVisible (v) {
      if (!v) {
        this.form = {
          departmentName: '',
          parentId: ''
        }
        this.$refs.form.clearValidate()
      }
    }
  },

  methods: {
    append (type) {
      this.type = type
      this.dialogVisible = true
    },

    submitAppend () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.submiting = true
          this.$http({
            url: this.$http.adornUrl(`/user/${this.type === 1 ? 'base' : 'department'}/save`),
            method: 'post',
            data: this.$http.adornData(this.form)
          }).then((data) => {
            this.loadDepartmentList()
            this.dialogVisible = false
            this.submiting = false
            this.onSuccess()
          }).catch(() => {
            this.submiting = false
          })
        }
      })
    },

    remove (data, type) {
      this.$http({
        url: this.$http.adornUrl(`/user/${type === 1 ? 'base' : 'department'}/deleteById`),
        method: 'post',
        data: data.id
      }).then((data) => {
        this.loadDepartmentList()
        this.onSuccess()
      })
    },

    edit (data, type) {
      const _this = this
      this.$prompt('部门名称', '编辑', {
        inputValue: data.name,
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(({ value }) => {
        if (!value || !value.trim()) return
        _this.$http({
          url: _this.$http.adornUrl(`/user/${type === 1 ? 'base' : 'department'}/updateById`),
          method: 'post',
          data: _this.$http.adornData({ id: data.id, departmentName: value })
        }).then((data) => {
          _this.loadDepartmentList()
          _this.onSuccess()
        })
      }).catch(() => { })
    },

    handleDrop (draggingNode, dropNode, dropType, ev) {
      let parentId
      if (dropType === 'inner') {
        parentId = dropNode.data.id
      } else {
        if (dropNode.level === 1) {
          parentId = 0
        } else {
          parentId = dropNode.parent.data.id
        }
      }
      this.$http({
        url: this.$http.adornUrl(`/user/department/updateById`),
        method: 'post',
        data: this.$http.adornData({ id: draggingNode.data.id, parentId })
      }).then((data) => {
        this.loadDepartmentList()
        this.onSuccess()
      })
    },

    async loadDepartmentList () {
      const data = await this.$http({
        url: this.$http.adornUrl('/user/departmentAll/list'),
        method: 'post'
      })
      this.departmentList = data.datas
    },

    onSuccess () {
      this.$opts.success()
    }
  },

  created () {
    this.loadDepartmentList()
  }
}
</script>

<style lang="scss">
.organization-view {
  padding: 20px;
  height: 100%;
  .custom-tree-node {
    flex: 1;
    display: flex;
    justify-content: space-between;
    .tree-icons {
      i {
        color: #409eff;
        margin-left: 10px;
        cursor: pointer;
        transition: all 0.2s;
        &:hover {
          color: rgb(121, 187, 255);
        }
      }
    }
  }
  .add-icon {
    float: right;
    color: #409eff;
    cursor: pointer;
    transition: all 0.2s;
    &:hover {
      color: rgb(121, 187, 255);
    }
  }
}
</style>
